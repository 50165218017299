import React from "react"
import Layout from "../components/layout"
import Box from "@mui/material/Box"

function About() {
  return (
    <Layout>
      <Box sx={{ maxWidth: { lg: "50%", xs: "90%" }, mx: "auto", fontSize: "1.4rem", lineHeight: 1.9 }}>
        <Box sx={{ my: 5 }}><h1>Mila Fay</h1></Box>
        <p>
          My work is a reflection of our existence; always bound by beauty and love. I release my passion and
          let
          the unexpected shine through. Trusting my intuitive nature pushes me to endure, to manifest and
          deliver
          thoughtful and dimensional creations which resonate with others.
        </p>

        <p>By embracing authenticity, I peer deeply into the expression of people and the world around us. I
          believe in taking risks, being genuine and embracing spirituality. I wish to inspire the same in
          others.
          I find pleasure in escapism of dreamy fantasy world; it could be the perfectionist in me that is
          unwilling to accept an imperfect reality. I love to transform forgotten relics into beautiful
          reincarnations, instilling wonder and delight into the ordinary.
        </p>
        <p>My unrelenting drive for art, design and exploration delivers unique and exciting experiences for my
          clients and fans. The momentum fills me with pure joy and positive energy, which inspires me to
          create
          more and continue sharing ONLY LOVE. I hope to bring and expand that energy and intention into Our
          World.
        </p>
      </Box>

    </Layout>
  )
}

export default About